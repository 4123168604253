$primary: #0e387a;
$secondary: #f5f5f5;
$cards-background: white;
$second-font-color:white;
$shade-of-red: #cc001a;
$side-bar-items-hover: #53709c;

$boxx-yellow: #FCF55F;
$boxx-gray: rgb(240, 240, 240);

$boxx-gradient: linear-gradient(#1C082E, #3B115D);
$boxx-gradientReversed: linear-gradient(#3B115D,#1C082E);
$base-color: #32be8f;
$second-color: #ebf2f2;