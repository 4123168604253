.client-details-name-wrapper {
  width: max-content;
  padding: 20px 70px 20px 70px;
  margin: 20px 0px 25px 0px;
}

.contact-info {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  .MuiTypography-root {
    margin-right: 2rem;
  }
}
