@import "../../styles/colors";

.form {
  background: rgb(255, 255, 255);
  width: auto;
  height: auto;
  border-radius: 7px;
}

.text {
  /* color: hsl(0, 0%, 41%);
  border-bottom: 5px solid #32be8f;
  border-radius: 17%;
  width: 100%;
  margin-left: auto;
  margin-right: auto; */

  margin-bottom: 20px;
  color: rgb(119, 119, 119);
  /* text-decoration: underline solid green;
  padding-bottom: 10px; */
  padding-bottom: 5px;
  border-bottom: 4px solid $primary;
  /* border-bottom: 4px solid #22ff91; */

  line-height: 35px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 17px;
}

.label {
  font-weight: bold;
}

.login-icons {
  color: $primary;
}

.grid > div {
  color: $primary !important;
}
