@import "../../styles/colors";

.filter-card {
  .kyc-type-form-control {
    margin-top: 7px;
  }

  .kyc-type-select {
    .MuiOutlinedInput-notchedOutline {
      border-color: $primary;
      border-width: 2px;
    }
  }

  .kyc-type-input {
    color: $primary;
  }
}
