@import '../../../styles/_colors.scss';

.add-edit-token {
  text-align: center;
}

.token-save {
  margin-left: 5px !important;
}

.files-list-item {
  width: 100%;
  margin: 5px 0 5px 0;
}

.file {
  display: flex;
  align-items: center;
  color: darkblue;
  margin-bottom: 2px;
}

.file-icon {
  margin-right: 5px;
  color: darkblue;
}

.remove-icon {
  color: $shade-of-red;
}

.token-buttons {
  float: right;
}

.token-delete {
  background-color: $shade-of-red !important;
}