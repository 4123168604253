@import '../../styles/colors';

.navigationbar > div {
  background: $primary;
}

.navigation-drawer {
  .sidebar-header {
    background: $primary;

    .sidebar-header-icon {
      color: $secondary;
    }
  }

  li .Mui-selected {
    background-color: $side-bar-items-hover !important;
  }


  .sideabar-list {
    background: $primary;
    height: 100%;

    .sidebar-icon {
      color: $secondary;
    }

    .sidebar-link {
      text-decoration: none;
      width: 100%;
      color: $secondary;
    }

    .sidebar-subItem {
      max-width: 100%;
      padding-left: 20px;
    }

  }
}

.notification-icon-link {
  display: flex;
  margin-left: auto;
}

.notification-icon > svg {
    color: white;
}

.notification-icon{
  color: white;
  font-size: 39px !important;
}