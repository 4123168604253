:root{
    --base-color: #32be8f;
    --second-color: #ebf2f2;
}
.check-box-list-div{
    display: flex;
    width: 70%;
    justify-content: center;
}
.check-box-list-div > div > div{
    margin-bottom: 22px;
    background-color: var(--second-color);
}
.check-box-list {
    display: flex ;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.check-box-label{
    width: 150px
}
.list-item-paper{
    padding: 10px;
    margin: 5px;
    display: flex;
}
.check-box-icon > svg{
    color: var(--base-color) !important;
}