.notification-popper-title {
  border-bottom: 1px solid lightgray;
  padding: 10px 10px 10px;
  overflow-y: auto;
  display: flex;
  justify-content: space-between;
}

.notification {
  cursor: pointer;
}
