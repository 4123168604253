@import '../../styles/colors';

.notification {
  display: flex !important;
  justify-content: space-between !important;
}

.new-notification {
  background-color: #e8f0fc;
}
