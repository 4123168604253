@import '../../../../styles/colors';

.note-form {
  width: 100%;
  padding: 20px;
}

.post-note-card {
  width: 100%;
  background-color: $secondary !important;
}

.note {
  width: 100%;
  min-height: 70px;
  margin-top: 20px;
  background-color: $secondary !important;
}

.note > div {
  padding: 20px;
}

.note-attach-chip {
  margin: 20px !important;
  cursor: pointer !important;
  background-color: $primary !important;
  color: white !important;
}

.note-date {
  float: right;
}

.note-smaller-font {
  font-size: 13px;
}

.note-load-more-button {
  margin-top: 20px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.empty-notes-text {
  margin-top: 20px;
}

.note-topic {
  margin-bottom: 10px !important;
}