.acountMenu_link {
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
}

.avatar-menu {
  width: 32px !important;
  height: 32px !important;
  font-size: 20px !important;
}
