@import '../../styles/colors';

.table-color {
  color: black;
}
.success-color {
  color: #0d9d8d !important;
}
.decline-color{
  color:$shade-of-red !important;
}

tbody > tr:hover th {
  cursor: pointer;
}

.pagination-div > div > div > svg {
  color: black;
}
.pagination-div > div > div > button {
  color: black !important;
}
.tableContainer {
  padding-left: 20px;
  padding-right: 20px;
}
thead > tr > th {
  padding: 0 !important;

  text-align: center !important;
  color: $primary
}

th.searchCell {
  background: transparent !important;
  color: var(--green) !important;
}

th.searchCell > div {
  color: var(--green) !important;
}

th.headerCell {
  min-width: 100px;
  background-color: $primary;
}
th.headerCell > div {
  background-color: $primary;
  color: $second-font-color;
}

th {
  border: none !important;
  font-family: "Poppins" !important;
}

thead > tr > th {
  padding-left: 2px !important;
  padding-right: 2px !important;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
  font-family: "Poppins" !important;
  height: 100%;
}
.tableHead > tr > th:first-child {
  border-radius: 20px 0px 0px 0px;
}
.tableHead > tr > th:last-child {
  border-radius: 0px 20px 0px 0px;
}
.tableHead > tr > th > div:last-child {
  border-radius: 20px 20px 0px 0px;
}

thead > tr > th:first-child {
  padding-left: 0 !important;
}
thead > tr > th:last-child {
  padding-right: 0 !important;
}
thead > tr > th > div {
  border-radius: 2px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

thead > tr > th.searchCell > div {
  align-items: end;
}

table {
  z-index: 10;
}
th.searchCell div {
  font-size: medium !important;
}

th > div > div > label {
}
th > div > div > div > input {
}
th > div > div {
  width: 98%;
}

.headerDatePicker {
  background: transparent;
  color: $cards-background !important;
}
.tableRowHeader {
  background-color: $primary;
  color: $second-font-color;
}
.emptyListRow {
  justify-content: center;
  text-align: center;
  width: 100% !important;
}
.emptyListCell {
  font-size: 15px !important;
  color: rgb(159, 154, 154) !important;
}
