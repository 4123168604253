@import "styles/_colors.scss";

.file-uploader {
  width: 50%;
}

.file-uploader > label {
  border: 2px dashed $primary;
  min-width: 200px;
  width: 16vw !important;
}

.file-uploader > label > svg > path {
  fill: $primary;
}

.selected-files-list {
  font-size: 12px;
  margin: 5px 0px 0px 10px;
}