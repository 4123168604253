@import "../../../../styles/colors";

.file-input-wrapper > label {
  border: 2px dashed $primary;
  min-width: 200px;
}

.file-input-wrapper > label > svg > path {
  fill: $primary;
}

.file-input-wrapper {
  font-size: 12px;
}

.message-wrapper {
  background-color: $cards-background;
  border-radius: 10px;
  width: 75%;
  margin: 0 auto 0 auto;
}

.conversation-message-card {
  background-color: white;
  border-radius: 10px;
}

.client-mlro-page {
  height: 100vh;
}

.client-mlro-page-header-wrapper {
  margin-bottom: 20px;
  color: rgb(119, 119, 119);
  padding-bottom: 5px;
  border-bottom: 4px solid $primary;
  line-height: 35px;
  min-width: 32rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 17px;
}

.client-mlro-page-form-wrapper {
  border-radius: 1%;
  padding-bottom: 20px;
  border: solid 3px #32be8f;
}

.conversation-title-wrpper {
  padding: 10px;
  margin-top: 40px;
  width: 100%;
}

.client-mlro-enter-message-wrapper {
  border: 3px solid white;
  border-radius: 10px;
}

.client-mlro-conversation-avtar {
  background-color: $primary !important;
  margin-right: 10px;
}

.expired-link-wrapper {
  display: flex;
  align-items: center;
  height: 120px;
}

.expired-link-message {
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  font-size: 25px !important;
  color: #f86060;
  text-align: center;
  margin-bottom: 20px;
  padding: 5px;
  border-radius: 10px;
}
