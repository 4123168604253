.access_denied_back_container{
    object-fit: contain;
    background: white;
    font-size: 22px;

    width: 100%;
    position: absolute;

    top: 10%;
    bottom: 0;
}
.access_denied_background_img{
    object-fit: contain;
    height: 50%;
    width: 100%;

}
.access_denied_text_info{
    font-family: "Segoe UI Emoji"
}
.access_denied_header{
    font-family: "Segoe UI Emoji"
}
.access_denied_back-button{
    zoom: 120%;
}
