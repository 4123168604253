@import "../../../../styles/colors";

.second {
  width: 350px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 10px 10px 5px #aaaaaa;
}

.text1 {
  font-size: 13px;
  font-weight: 500;
  color: #56575b;
}

.conversation-wrapper {
  background-color: $secondary;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;

  .send-message {
    display: flex;
  }

  .send-btn {
    height: fit-content;
    cursor: pointer;
    color: #0b4886;
    margin: auto 0 auto 0.5rem;
  }
}

.mlro-client-conversation-message-wrapper {
  background-color: $cards-background;
  border-radius: 10px;
}

.conversation-message-avatar {
  background-color: $primary !important;
  margin-right: 10px;
}
