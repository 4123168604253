@import '../../styles/colors';

.box-style {
  color: white;
  margin: 5px;
}

.country-container {
  background: $cards-background;
  padding: 15px 40px;
  box-shadow: 0 0 5px #000000;
  margin: 30px 0;
}

.search-container {
  background: $cards-background;
  padding: 15px 0;
  box-shadow: 0 0 5px #000000;
}

.checkbox-style {
  margin: 0;
  color: white !important;
}

.continentSelect {
  margin: 0px -20px;
  color: $primary !important;
  font-weight: bold !important;
  min-width: 300px;
  border: 1px solid $primary;
}

.form-control {
  background-color: $primary;
  border-radius: 17px;
  padding: 0 15px;
  margin: 5px 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.country-options-wrapper {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.button-upload {
  font-weight: 50;
  border: 0.15rem solid !important;
}

.hidden-button {
  display: none;
}