@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
@import "../../styles/colors";
.approvers_header-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 20px;
    padding: 0px 25px 0px 0px;
}
.approvers_header-wrapper > h1{
    margin: 0px;
}

.chip {
    font-size: 18px !important;
    padding: 0px 30px 0px 10px !important;
    width: 500px;
    height: 70px !important;
    display: flex;
    justify-content: space-between !important;
    background-color: white !important;
    border: 3px solid $primary !important;
}

.chip-avatar {
    height: 50px !important;
    width: 50px !important;
    margin-left: 0px !important;
    background-color: $primary !important;
    color: #FFF5F9 !important;
}

.chip-icon {
    height: 50px !important;
    width: 50px !important;
    color: black !important;
}

.chip-content {
    display: flex;
    justify-content: start;
    flex-direction: column;
    width: 330px;
}

.autocomplete {
    margin-top: 10px;
    padding: 5px;
}

.allUsersApprovers {
    margin-top: 20px;
}

