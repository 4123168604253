@import "../../../styles/colors";

.procedure-title-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
}

.procedure-time-wrapper {
  max-width: 85% !important;
  min-width: 85% !important;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.procedure-time {
  display: flex;
  align-items: center;
  margin-top: 3px;
  width: 100%;
  min-width: max-content;
}

.procedure-icon {
  margin-right: 10px;
  color: $primary;
}

.procedure-file-icon {
  margin-right: 5px;
  color: darkblue;
}

.procedure-data-wrapper {
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.empty-procedure {
  justify-content: center;
  padding: 5px 5px 5px 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.procedure-content {
  padding: 0px 20px 0px 20px;
}

.procedure-list {
  width: 100%;
}

.procedure-list-item {
  width: 100%;
  margin: 5px 0px 5px 0px;
}

.procedure-question-title {
  color: $primary;
}

.procedure-question-answer {
  padding-left: 15px;
}

.procedure-question-file-wrapper {
  background-color: ghostwhite;
  border-radius: 5px;
  padding: 10px 10px 10px 10px;
}

.procedure-question-file {
  display: flex;
  align-items: center;
  color: darkblue;
  margin-bottom: 2px;
}

.procedure-user-selfie {
  border-radius: 50%;
  object-fit: cover;
}

.procedure-user-card {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 7px;
  max-width: 400px;
  min-width: 300px;
}

.procedure-image-file {
  width: 99.8%;
  border-radius: 10px;
}

.country-status-label {
  display: inline;
}

.country-status-value {
  display: inline;
}
