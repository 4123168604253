.spinner-container {
  position: fixed;
  height: 100%;
  width: 100%;
  display: table;
  left: 0%;
  top: 0%;
  opacity: 40%;
  text-align: center;
  background-color: black;
  z-index: 11111;
}