.procedure-pending {
  color: orange;
}

.procedure-accepted {
  color: green;
}

.procedure-rejected{
  color: red;
}

.procedure-auto-declined{
  color: darkred;
}

.procedure-new{
  color: #0b4886;
}