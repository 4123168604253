@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

.user_header-wrapper {
  justify-content: space-between;
  align-items: end;
  margin-left: 20px;
  padding: 25px 25px 0px 0px;
}

.user_header-wrapper > button {
  float: right;
}

.user_header-wrapper > h1 {
  margin: 0px;
}

#customized-dialog-title {
  font-family: "Poppins" !important;
}

.modalContent {
  font-family: "Poppins" !important;

}

.customTextField input {
  color: white !important;

  border-color: white;
}

.customTextField input:hover {
  color: white !important;
  border-color: white;

}

.customTextField {
  color: white !important;
  border-color: white;

}

.customTextField:hover {
  color: white !important;
  border-color: white;

}

.customTextField > fieldset {
  color: white !important;
  border-color: white;
}