@import './styles/colors';
@import "./styles/gbg-styles.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $second-font-color;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.modalCloseButton{
  background-color:$shade-of-red !important;
  color: white !important;

}
.modalSuccesButton{
  background-color:#0d9d8d !important;
  color: white !important;
}
.baseFontFamily{
  font-family: "Poppins" !important;
}
.swal2-container{
    z-index: 11111!important;
}
.w-100{
  width: 100%;
}
.link-element{
  text-decoration: none;
  color: #00000D;
}
.edit-icon-wrapper{
  background-color: $primary !important;
}
.edit-icon-white{
  color: $second-font-color !important;
}
.delete-icon-wrapper-purple{
  background-color: $shade-of-red !important;
}
.delete-icon-white{
  color: $second-font-color !important;
}
.border-light{
box-shadow: 1px 1px 0.4em $primary !important;
}

.custom-border-shadow-card{
  border:1px solid #ecebeb !important;
  box-shadow: none !important;
  filter: drop-shadow(0 20px 13px rgb(0 0 0/.03)) drop-shadow(0 8px 5px rgb(0 0 0/.08)) !important;
}

.action-pointer{
  cursor: pointer;
}