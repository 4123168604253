@import "../../styles/colors";
.form{
    background: rgb(240, 240, 240);
    width: auto;
    height: auto;
    border-radius: 7px;
}

.grid{
    background: linear-gradient(120deg, #e3e3e3, #e3e3e3);
}
.grid > div{
    border: 3px solid $primary;
}

.autofillInput:-webkit-autofill {
    color: #000 !important;
    border-color: #ccc !important;
}

.text{
    /* color: hsl(0, 0%, 41%);
    border-bottom: 5px solid #32be8f;
    border-radius: 17%;
    width: 100%;
	margin-left: auto;
	margin-right: auto; */

    margin-bottom: 20px;
    color: rgb(119, 119, 119);
    /* text-decoration: underline solid green;
    padding-bottom: 10px; */
    padding-bottom: 5px;
    border-bottom: 4px solid #32be8f;
    /* border-bottom: 4px solid #22ff91; */

    line-height: 35px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 17px;
}

.label {
    font-weight: bold;
}

.login-links{
    display: block;
    text-align: right;
    text-decoration: none;
    color: #727272;
    font-size: 0.9rem;
    transition: .3s;
    width: 86%;
    /* margin-right: 73px; */
}

.custom-form {
    /* Resetting margin and padding */
    margin: 0;
    padding: 0;

    /* Resetting default form styles */
    border: none;
    background: none;
    outline: none;
    /* ...add more specific styles as needed */
}

#input-with-sx-mail, #input-with-sx-password {
    margin-left: 5px !important;
}
.login-icons{
    color: $primary;
}